<template>
	<div class="page_box">
		<div class="bom_box">

			<div class="account-titlebox">
				<div class="title">{{page_title}}</div>
				<div class="two-toggle">
					<div class="tabs">
						<span v-for="(item,index) in guanli" :key="index" :class="{active:activeIndex == item.status}"
							@click="toStatus(item)">{{item.title}}</span>
					</div>
					<div class="end-end">
						<span class="all" @click="do_all_read()">全部标记已读</span>
						<span class="clear" @click="do_all_clear()">清空</span>
					</div>
				</div>
			</div>

			<div class="bom_box_list">
				<div class="list-list">
					<div class="item-item" v-for="(item,index) in log_list" :key="index">
						<div class="left">{{item.title}}</div>
						<div class="right">{{item.created_at}}</div>
					</div>
				</div>

				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>

		</div>

	</div>
</template>
<script>
	import page from "@/components/page/page.vue"; //分页  统一的
	export default {
		components: {
			page,
		},
		data() {
			return {
				forms: {
					is_read: 0, //0全部 1未读 2 已读
				},
				pages: {
					page: 1,
					limit: 10,
				},
				guanli: [{
						title: '全部',
						status: 0,
					},
					{
						title: '未读',
						status: 1,
					},

				],
				log_list: [],
				//

				dialogVisible: false,
				activeIndex: 0, //默认选中

				form_select: {
					juese: '',
					zhuangtai: '',
					zhanghao: '',
				},

				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据

				list: [{
						zhanghao: '123123',
						name: '张三',
						juese: '角色',
						chuangjia: '2024-01-01 15:20:10',
						zuihou: '2024-01-01 15:20:10',
						status: '启用'
					},
					{
						zhanghao: '123123',
						name: '张三',
						juese: '超级管理员',
						chuangjia: '2024-01-01 15:20:10',
						zuihou: '2024-01-01 15:20:10',
						status: '启用'
					},

				],

				count: 0,
				times: [], //时间范围
			};
		},
		computed: {},
		watch: {},
		created() {
			this.page_title = this.$route.meta.title
			this.get_list()
		},
		mounted() {

		},

		methods: {
			toStatus(item) {
				this.activeIndex = item.status
				this.forms.is_read = item.status
				this.pages.page = 1;
				this.get_list()
			},

			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				console.log(this.forms)
				this.loading = true
				this.$api("getAdminLogList", {
					// mobile:this.mobile
					...this.forms,
					...this.pages,
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						this.log_list = res.data.list
					} else {
						alertErr(res.msg)
					}
				});
			},

			do_all_read() {
				this.$confirm('确认将所有消息设为已读?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api("editAdminLog", {
						ids: '',
						all: 1, //0 1全部设置已读
					}, "post").then((res) => {
						alert(res)
						if (res.code == 200) {
							this.get_list()

							this.$store.commit('set_vuex_unread_number', 0)
						}
					});
				}).catch(() => {

				});
			},

			do_all_clear() {
				this.$confirm('确认清空所有消息?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api("editAdminLog", {
						// id: row.id,
						ids: '',
						all: 1,
						clear: 1
					}, "post").then((res) => {
						alert(res)
						if (res.code == 200) {
							this.get_list()
						}
					});
				}).catch(() => {

				});
			},


		},


	};
</script>

<style scoped lang="less">
	.list-list {
		.item-item {
			display: flex;
			padding: 17px 0;
			border-bottom: 1px solid #EDEDED;
			justify-content: space-between;

			.right {
				color: #949494;
				font-size: 12px;
			}

			.left {
				width: 400px;
				display: flex;
				align-items: center;
				font-size: 14px;
				color: #3E3E3E;

				&::before {
					content: '';
					display: block;
					width: 5px;
					height: 5px;
					background: #E4E4E4;
					margin-right: 17px;
				}
			}
		}
	}

	.two-toggle {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.end-end {
		display: flex;

		span {
			height: 40px;
			border-radius: 4px 4px 4px 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 25px;
			cursor: pointer;
			font-size: 14px;

			&:hover {
				opacity: 0.854;
			}
		}

		.all {
			color: #fff;
			background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
		}

		.clear {
			background: #FFFFFF;
			border-radius: 2px 2px 2px 2px;
			border: 1px solid #EFEFEF;
			margin-left: 9px;

		}
	}

	/deep/.el-dialog {
		border-radius: 8px;
	}

	.content-info {
		padding: 0px 110px;

		.form-item {
			display: flex;
			align-items: center;
			margin-bottom: 14px;

			.values {
				flex: 1;
			}

			.chuangjian {
				width: 101px;
				height: 38px;
				background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
				border-radius: 4px 4px 4px 4px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-size: 16px;
				margin-left: 14px;
				cursor: pointer;

				&:hover {
					opacity: 0.78;
				}
			}



			.select {
				flex: 1;
				display: flex;

				/deep/.el-select {
					flex: 1;
				}
			}
		}

		.title {
			font-size: 16px;
			display: flex;
			width: 85px;
			margin-right: 5px;

			&.requird::before {
				content: '*';
				display: block;
				color: red;
				margin-right: 5px;
			}
		}

	}


	.created-span {
		width: 80px;
		height: 31px;
		background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
		border-radius: 4px 4px 4px 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		color: #fff;
		cursor: pointer;

		&:hover {
			opacity: 0.85;
		}
	}

	.created-account {
		padding: 21px 21px 0 21px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.end-flex {
			display: flex;
			align-items: center;

			.item-1 {
				margin-left: 27px;

				.key {
					margin-right: 10px;
					font-size: 14px;
					color: #424542;
				}
			}
		}
	}

	.seacher-box {
		width: 80px;
		margin-left: 14px;
		height: 40px;
		background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
		border-radius: 0px 0px 0px 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-size: 14px;
		cursor: pointer;

		&:hover {
			opacity: 0.85;

		}
	}

	.account-titlebox {
		.title {
			color: #333333;
			font-size: 14px;
			font-weight: bold;
			padding: 12px 17px;
			border-bottom: 1px solid #EDF0F6;
		}

		.two-toggle {
			padding: 0 21px;
			border-bottom: 1px solid #eee;

			.tabs {
				display: flex;
				// border-bottom: 1px solid #D9D9D9;

				span {
					width: 100px;
					color: #333333;
					font-size: 16px;
					display: flex;
					cursor: pointer;
					padding: 22px 0px 16px 0px;
					position: relative;
					justify-content: center;
					align-items: center;
					margin-right: 10px;

					&.active {
						color: #3697FD;

						&::after {
							content: '';
							position: absolute;
							width: 100%;
							height: 2px;
							background: #0564ca;
							bottom: 0;
							left: 0;
						}
					}
				}
			}
		}


	}


	/deep/ .el-table__cell:nth-child(6) {
		text-align: center;
	}

	/deep/.el-table__cell:nth-child(7) {
		text-align: center;
	}

	/deep/.el-table__cell:nth-child(1) {
		padding-left: 50px;
		padding-right: 50px;
	}

	.qiyong {
		width: 69px;
		height: 30px;
		background: #FFF0CA;
		border-radius: 2px 2px 2px 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #EC7217;
		font-size: 14px;
		margin: 0 auto;
	}

	.zhanghao-flex {
		display: flex;
		justify-content: center;
		align-items: center;

		span {
			font-size: 14px;
			color: #3697FD;
			margin: 0 27px 0 0;
			cursor: pointer;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	// ---------------------------------






	.page_box {
		height: 100%;

		// background-color: red;
		.bom_box {
			padding: 20px;
		}

		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;



			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #ac8d8d;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}
		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				// width: 1640px;
				// height: 670px;
				// padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>